import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2f9a5a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "track-orders main-container",
  ref: "trackOrdersWrapperRef"
}
const _hoisted_2 = { class: "track-orders-title page-headline" }
const _hoisted_3 = {
  key: 3,
  class: "track-orders-summary"
}
const _hoisted_4 = { class: "track-orders-params" }
const _hoisted_5 = { class: "flex-wrapper" }
const _hoisted_6 = { class: "track-orders--page-counters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      ($setup.showUpdateDeliveryDateModal)
        ? (_openBlock(), _createBlock($setup["UpdateDeliveryDateModal"], {
            key: 0,
            show: $setup.showUpdateDeliveryDateModal,
            "onUpdate:show": [
              _cache[0] || (_cache[0] = ($event: any) => (($setup.showUpdateDeliveryDateModal) = $event)),
              $setup.onUpdateDeliveryDateModalClose
            ],
            id: $setup.updateDeliveryDateId,
            deliveryDate: $setup.updateDeliveryDateDeliveryDate,
            targetDate: $setup.updateDeliveryDateTargetDate,
            orderType: $setup.deliveryDateOrderType,
            shippingDate: $setup.updateShippingDate,
            onDateUpdated: $setup.onDeliveryDateUpdated,
            onClose: $setup.onUpdateDeliveryDateModalClose
          }, null, 8, ["show", "id", "deliveryDate", "targetDate", "orderType", "shippingDate"]))
        : _createCommentVNode("", true),
      ($setup.showUpdateProductionStageModal)
        ? (_openBlock(), _createBlock($setup["UpdateProductionStageModal"], {
            key: 1,
            show: $setup.showUpdateProductionStageModal,
            "onUpdate:show": [
              _cache[1] || (_cache[1] = ($event: any) => (($setup.showUpdateProductionStageModal) = $event)),
              $setup.onUpdateProductionStageModalClose
            ],
            orderId: $setup.updateProductionStageOrderId,
            partId: $setup.updateProductionStagePartId,
            stageId: $setup.updateProductionStageStageId,
            status: $setup.updateProductionStageStatus,
            costingId: $setup.updateProductionStageCostingId,
            onProductionStageUpdated: $setup.onProductionStageUpdated,
            onClose: $setup.onUpdateProductionStageModalClose
          }, null, 8, ["show", "orderId", "partId", "stageId", "status", "costingId", "onClose", "onUpdate:show"]))
        : _createCommentVNode("", true),
      ($setup.showChangeStatusModal)
        ? (_openBlock(), _createBlock($setup["ManualStatusUpdateModal"], {
            key: 2,
            show: $setup.showChangeStatusModal,
            "onUpdate:show": [
              _cache[2] || (_cache[2] = ($event: any) => (($setup.showChangeStatusModal) = $event)),
              $setup.onUpdateStatusClose
            ],
            order: $setup.rowForChangeStatus,
            onSave: $setup.onUpdateStatusSave,
            onClose: $setup.onUpdateStatusClose
          }, null, 8, ["show", "order"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode("Track Orders"),
        _createVNode($setup["MainTrackOrdersMenu"], {
          orderType: $setup.orderView,
          params: $setup.getExportParams,
          disabled: !$setup.orders.length
        }, null, 8, ["orderType", "params", "disabled"])
      ]),
      ($setup.isCustomerView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode($setup["TrackOrdersSummary"], {
              data: $setup.orderSummary,
              activeGroupByTimeKey: $setup.activeGroupByTimeKey,
              activeGroupByKey: $setup.activeGroupByKey,
              onUpdateActiveGroupByTimeKey: $setup.onUpdateActiveGroupByTimeKey,
              onUpdateActiveGroupByKey: $setup.onUpdateActiveGroupByKey
            }, null, 8, ["data", "activeGroupByTimeKey", "activeGroupByKey"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["NSelect"], {
            class: "main-select track-orders-select",
            value: $setup.orderView,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.orderView) = $event)),
            options: $setup.orderViewOptions
          }, {
            arrow: _withCtx(() => [
              _createVNode($setup["ChevronDown"])
            ]),
            _: 1
          }, 8, ["value", "options"]),
          _createVNode($setup["TrackOrdersFilters"], {
            filterByOwner: $setup.filterByOwner,
            filterByFlagged: $setup.filterByFlagged,
            filterByDeliveryStatus: $setup.filterByDeliveryStatus,
            filterByDueDateRange: $setup.filterByDueDateRange,
            filterByDueDateQuickActions: $setup.filterByDueDateQuickActions,
            filterByDeliveryDateRange: $setup.filterByDeliveryDateRange,
            filterByDeliveryDateQuickActions: $setup.filterByDeliveryDateQuickActions,
            filterByCustomer: $setup.filterByCustomer,
            filterByManufacturer: $setup.filterByManufacturer,
            filterByOrderType: $setup.filterByOrderType,
            filterByStatus: $setup.filterByStatus,
            filterByCustomerStatus: $setup.filterByCustomerStatus,
            filterBySearchQuery: $setup.filterBySearchQuery,
            filterByOpenOrderLines: $setup.filterByOpenOrderLines,
            filterByStatusUpdatedMethod: $setup.filterByStatusUpdatedMethod,
            isSearchScheduled: $setup.isSearchScheduled,
            isCustomerView: $setup.isCustomerView,
            owners: $setup.owners,
            customers: $setup.customers,
            manufacturers: $setup.manufacturers,
            loading: $setup.loading,
            filterCounters: $setup.filterCounters,
            onTriggerFilter: $setup.onFilterTriggered,
            onTriggerFilterOverwriteSet: $setup.onFilterTriggeredOverwriteSet,
            onTriggerFilterByOrderType: _cache[4] || (_cache[4] = ($event: any) => ($setup.filterByOrderType = !$setup.filterByOrderType)),
            onRemoveFilterByOrderType: $setup.onRemoveFilterByOrderType,
            onRemoveFilter: $setup.onRemoveFilter,
            onClearFilters: $setup.onClearFilters,
            onScheduleSearch: _cache[5] || (_cache[5] = ($event: any) => ($setup.isSearchScheduled = true)),
            onTriggerScheduledSearch: $setup.onTriggerScheduledSearch,
            onSetSavedFilters: $setup.setSavedFilters,
            onSetLoadingState: $setup.setLoadingState
          }, null, 8, ["filterByOwner", "filterByFlagged", "filterByDeliveryStatus", "filterByDueDateRange", "filterByDueDateQuickActions", "filterByDeliveryDateRange", "filterByDeliveryDateQuickActions", "filterByCustomer", "filterByManufacturer", "filterByOrderType", "filterByStatus", "filterByCustomerStatus", "filterBySearchQuery", "filterByOpenOrderLines", "filterByStatusUpdatedMethod", "isSearchScheduled", "isCustomerView", "owners", "customers", "manufacturers", "loading", "filterCounters"])
        ]),
        _createVNode($setup["SearchInput"], {
          modelValue: $setup.filterBySearchQuery,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.filterBySearchQuery) = $event)),
          onChange: $setup.onSearch
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", {
        class: "track-orders--table-wrapper",
        style: _normalizeStyle({ minHeight: `${$setup.scrollableTableHeight}px` })
      }, [
        _createVNode($setup["NDataTable"], {
          class: "track-orders--table",
          columns: $setup.currentColumns,
          data: $setup.orders,
          singleLine: false,
          ref: "tableRef",
          rowProps: $setup.rowProps,
          loading: $setup.loading,
          "scroll-x": $setup.isCustomerView ? 1887 : 1450,
          style: _normalizeStyle({ height: `${$setup.tableHeight}px` }),
          flexHeight: "",
          "onUpdate:sorter": $setup.handleSorterChange
        }, null, 8, ["columns", "data", "loading", "scroll-x", "style"])
      ], 4),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("div", { class: "track-orders--counter" }, _toDisplayString($setup.paginationCurrentItemsMessage), 513), [
          [_vShow, !$setup.loading]
        ]),
        _withDirectives(_createVNode($setup["NPagination"], {
          class: "track-orders--pagination",
          page: $setup.currentPage,
          "onUpdate:page": [
            _cache[7] || (_cache[7] = ($event: any) => (($setup.currentPage) = $event)),
            _cache[8] || (_cache[8] = ($event: any) => ($setup.onPaginationUpdated()))
          ],
          "page-count": $setup.totalPages,
          "page-slot": 6
        }, null, 8, ["page", "page-count"]), [
          [_vShow, $setup.totalPages > 1]
        ])
      ])
    ], 512), [
      [_vShow, $setup.isTrackOrdersRoute]
    ]),
    _createVNode(_component_router_view, {
      onDeliveryDateUpdated: $setup.onDeliveryDateUpdated,
      onProductionStageUpdated: $setup.onProductionStageUpdated,
      onEscalationUpdated: $setup.onEscalationUpdated,
      onCustomerStatusUpdated: $setup.onUpdateStatusSave
    })
  ], 64))
}