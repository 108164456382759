import { computed, onMounted, onUpdated, ref } from "vue";

export default () => {
  const sidebarWidth = ref(0); //sidebarWidth
  const sidebarWidthOffset = computed(() => {
    return {
      "--sidebar-width-offset": `${sidebarWidth.value}px`,
    };
  });

  const trackOrdersSummaryTotalWidth = ref(0); //sidebarWidth
  const trackOrdersSummaryTotalOffset = computed(() => {
    return {
      "--track-orders-summary-total-width-offset": `${trackOrdersSummaryTotalWidth.value}px`,
    };
  });
  onMounted(() => {
    setSidebarWidth();
    setTrackOrdersSummaryTotalWidth();
  });

  onUpdated(() => {
    if (!sidebarWidth.value) {
      setSidebarWidth();
    }
    if (!trackOrdersSummaryTotalWidth.value) {
      setTrackOrdersSummaryTotalWidth();
    }
  });

  const setSidebarWidth = () => {
    const sidebarElement = document.getElementById("sidebar");
    sidebarWidth.value = sidebarElement ? sidebarElement.clientWidth + 1 : 0;
  };

  const setTrackOrdersSummaryTotalWidth = () => {
    const trackOrdersSummaryTotalElement = document.getElementById(
      "track-orders-summary-left-side"
    );
    trackOrdersSummaryTotalWidth.value = trackOrdersSummaryTotalElement
      ? trackOrdersSummaryTotalElement.clientWidth + 1
      : 0;
  };

  return {
    sidebarWidth,
    sidebarWidthOffset,
    trackOrdersSummaryTotalWidth,
    trackOrdersSummaryTotalOffset,
  };
};
